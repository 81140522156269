<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <v-container>
      <v-row
        justify="space-around"
        class="mt-4"
      >
        <v-col cols="4">
          <v-text-field
            ref="days"
            v-model.number="days"
            label="Days*"
            :rules="[rules.positiveNumber]"
            type="number"
            required
            min="0"
            placeholder="Days"
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
            ref="name"
            v-model="name"
            label="Coaching #*"
            :rules="[rules.required]"
            required
            placeholder="Email coaching name"
          />
        </v-col>
      </v-row>
      <v-tabs
        v-model="active"
        background-color="primary"
        dark
        slider-color="white"
      >
        <v-tab
          v-for="language in availableLanguages"
          :key="language"
          v-ripple
        >
          {{ language }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="active">
        <v-tab-item
          v-for="(content, i) in emailCoachingContent"
          :key="i"
        >
          <v-text-field
            ref="subject"
            v-model="content.subject"
            label="Subject"
            clearable
            placeholder="Enter a subject"
            class="mt-6"
            required
            @input="(subject) => updateContent({subject}, i)"
          />
          <editor
            :content="content.text"
            :submit-button-disabled="submitButtonDisabled"
            :submit-button-text="saveContent.text"
            :toolbar="'contentToolbar'"
            :show-cancel-button="showCancelButton"
            @keyUp="(text) => updateContent({text}, i)"
            @submit="save"
            @cancel="closeDialog"
          />
          <label
            v-if="saveContent.description"
            class="content-label"
          >{{ saveContent.description }}
          </label>
        </v-tab-item>
      </v-tabs-items>
      <label
        v-if="isNotAccumulated"
        class="error-label"
      >The email coaching schedule must be accumulated,
        days should be higher than {{ lastDeltaDays }}
      </label>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { randomPassword } from '@kickbox/common-util';
import {
  Editor,
  ValidateMixin,
  ContentService,
  LanguageService
} from '@kickbox/common-admin';
import emailCoachingService from '@/services/emailCoachingService';

export default {
  components: {
    Editor,
  },
  mixins: [ValidateMixin],
  props: [
    'dialog',
    'lastDeltaDays',
    'items'
  ],
  data() {
    return {
      availableLanguages: [],
      emailCoachingContent: [],
      active: 0,
      showCancelButton: true,
      days: this.lastDeltaDays + 1,
      name: '',
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    saveContent() {
      return {
        text: 'save*',
        description: '*All the languages are required.'
      };
    },
    isNotAccumulated() {
      return this.days <= this.lastDeltaDays;
    },
    submitButtonDisabled() {
      return this.emailCoachingContent.some((ec) => !ec.subject || !ec.text)
        || this.isNotAccumulated || !this.name;
    },
    getTemplateName() {
      const uid = randomPassword(8);
      return `campaignNewProject-${uid}`;
    },
  },
  async created() {
    const languages = await LanguageService.getLanguages(this.company.enabledLanguages);
    this.availableLanguages = languages.map((lang) => lang.key);
    this.setEmailCoachingContent();
  },
  methods: {
    setEmailCoachingContent() {
      this.availableLanguages.forEach((l) => {
        this.emailCoachingContent.push({
          name: this.getTemplateName,
          language: l,
          default: false,
          subject: '',
          text: '',
          customEnabled: true,
          company: this.$store.getters.company.parseObject,
        });
      });
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    async save() {
      this.emailCoachingContent.forEach((ec) => {
        ContentService.addContent(ec);
      });
      const template = {
        delta: this.days,
        name: this.name,
        key: this.getTemplateName,
        addedTemplate: true,
      };
      const updatedEmailCoaching = await emailCoachingService.addEmailCoachingTemplate(
        template, this.items
      );
      this.$store.commit('updateEmailCoachingItems', updatedEmailCoaching.get('items'));
      this.closeDialog();
    },
    updateContent(property, i) {
      return Object.assign(this.emailCoachingContent[i], property);
    }
  }
};
</script>

<style scoped>
  .container  {
    background-color: white;
  }
  .content-label {
    margin-bottom: 10px;
    display: inline-block;
  }
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }
  .error-label {
    color: red;
  }
</style>
