<template>
  <v-row
    dense
    justify="space-around"
    align="center"
    class="mt-4 email-coaching-item-container"
  >
    <confirm-dialog
      :show="showDeleteItemDialog"
      :confirm-click="removeItem"
      @close="showDeleteItemDialog = false"
    >
      <v-row>
        <div class="confirm-title">
          Do you want to remove the item "{{ name }}"?
        </div>
        <div class="confirm-sub-title">
          {{ rollBackText }}
        </div>
        <div class="confirm-sub-title">
          {{ removeItemText }}
        </div>
      </v-row>
    </confirm-dialog>
    <v-col cols="2">
      <v-text-field
        ref="delta"
        v-model.number="delta"
        :rules="[rules.positiveNumber, rules.required]"
        :disabled="firstEmailCoachingItem"
        type="number"
        required
        min="0"
        placeholder="Days"
      />
    </v-col>
    <v-col cols="8">
      <v-text-field
        ref="name"
        v-model="name"
        :rules="[rules.required]"
        required
        placeholder="Email coaching name"
      />
    </v-col>
    <v-col cols="1">
      <v-btn
        :disabled="firstEmailCoachingItem"
        icon
        small
        @click="showDeleteItemDialog = true"
      >
        <v-icon color="grey darken-1">
          delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { ValidateMixin, ConfirmDialog } from '@kickbox/common-admin';

export default {
  components: {
    ConfirmDialog
  },
  mixins: [ValidateMixin],
  props: {
    item: {
      required: true
    },
    emailCoachingItems: {
      required: true
    }
  },
  data() {
    return {
      delta: 0,
      name: '',
      key: '',
      showDeleteItemDialog: false,
      rollBackText: 'If you remove this item it cannot be restored.'
    };
  },
  computed: {
    firstEmailCoachingItem() {
      return this.emailCoachingItems[0].key === this.item.key;
    },
    removeItemText() {
      const firstItemText = 'The second item will take this place with 0 days.';
      const secondItemText = 'The rest of the days won\'t be changed.';
      return this.firstEmailCoachingItem ? firstItemText : secondItemText;
    }
  },
  watch: {
    item() {
      this.setItemData();
    },
    delta() {
      this.$emit('updateItem', {
        delta: this.delta,
        key: this.item.key,
      });
    },
    name() {
      this.$emit('updateItem', {
        name: this.name,
        key: this.item.key,
      });
    }
  },
  created() {
    this.setItemData();
  },
  methods: {
    removeItem() {
      this.showDeleteItemDialog = false;
      this.$emit('removeItem', this.key);
    },
    setItemData() {
      this.delta = this.item.delta;
      this.name = this.item.name;
      this.key = this.item.key;
    }
  }
};
</script>

<style>
  .email-coaching-item-container .input-group__details {
    min-height: 0 !important;
  }
  .confirm-title {
    font-size: 17px;
    margin-bottom: 1.4rem;
    max-width: 270px;
  }
  .confirm-sub-title {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 0.8rem;
    max-width: 265px;
  }
</style>
