<template>
  <v-container>
    <add-email-coaching
      v-if="showAddEmailCoaching"
      :dialog="showAddEmailCoaching"
      :last-delta-days="lastDeltaDays"
      :items="emailCoaching.items"
      @close-dialog="showAddEmailCoaching = false"
    />
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <div class="text-center">
          <v-progress-circular
            v-if="isLoading.emailCoaching"
            :indeterminate="isLoading.emailCoaching"
            color="primary"
          />
        </div>

        <v-card>
          <v-card-text v-if="emailCoaching">
            <v-container>
              <label
                class="title d-block"
              >
                Email coaching schedule
              </label>
              <label
                class="d-block mt-2"
              >
                *From the moment a project is subscribed to the email coaching, accumulated.
              </label>
              <v-row

                justify="space-around"
                class="mt-4"
              >
                <v-col cols="2">
                  <label>Days*</label>
                </v-col>
                <v-col cols="8">
                  <label>Coaching #*</label>
                </v-col>
                <v-col cols="1" />
              </v-row>
              <email-coaching-item
                v-for="item in emailCoaching.items"
                :key="item.key"
                :item="item"
                :email-coaching-items="emailCoaching.items"
                @updateItem="updateEmailCoachingArray"
                @removeItem="removeEmailCoachingTemplate"
              />
              <p
                class="add-link primary--text"
                @click="addEmailCoaching"
              >
                Add Email Coaching
              </p>
              <div class="mt-6">
                <app-button
                  :disabled="disabledSaveButton"
                  @click="save"
                >
                  Save
                </app-button>
              </div>
              <label
                v-if="isNotAccumulatedArray"
                class="error-label"
              >The email coaching schedule must be accumulated
              </label>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import emailCoachingService from '@/services/emailCoachingService';
import EmailCoachingItem from './EmailCoachingItem';
import AddEmailCoaching from './AddEmailCoaching';

export default {
  components: {
    EmailCoachingItem,
    AddEmailCoaching,
  },
  data() {
    return {
      disabledSaveButton: false,
      isNotAccumulatedArray: false,
      showAddEmailCoaching: false,
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'emailCoaching',
    ]),
    lastDeltaDays() {
      const { items } = this.emailCoaching;
      return items[items.length - 1].delta;
    }
  },
  async created() {
    this.$store.commit('setIsLoading', { emailCoaching: true });
    const emailCoaching = await emailCoachingService.getEmailCoaching();
    this.$store.commit('setIsLoading', { emailCoaching: false });
    this.$store.commit('setEmailCoaching', emailCoaching);
  },
  methods: {
    async save() {
      this.$store.commit('setIsLoading', { emailCoaching: true });
      if (!this.emailCoaching.default) {
        await emailCoachingService.updateEmailCoaching(
          this.emailCoaching.parseObject, this.emailCoaching.items
        );
      } else {
        const emailCoaching = await emailCoachingService.saveEmailCoaching(
          this.emailCoaching.items
        );
        this.$store.commit('setEmailCoaching', {
          default: false,
          items: emailCoaching.get('items'),
          parseObject: emailCoaching,
        });
      }
      this.$store.commit('setIsLoading', { emailCoaching: false });
      this.$store.dispatch('showSnackBar', { text: 'The email coaching schedule was updated.' });
    },
    updateEmailCoachingArray(event) {
      this.$store.commit('updateEmailCoachingItem', event);
      this.validateEmailCoachingArray();
    },
    validateEmailCoachingArray() {
      const invalidData = this.emailCoaching.items
        .some((item, index) => ((item.delta <= 0 || !item.delta) && index > 0) || !item.name);
      this.isNotAccumulatedArray = this.verifyIfDeltaIsAccumulated();
      this.disabledSaveButton = this.isNotAccumulatedArray || invalidData;
    },
    verifyIfDeltaIsAccumulated() {
      return this.emailCoaching.items.some((item, index, array) => {
        if (array[index - 1] && item.delta) {
          return (item.delta <= array[index - 1].delta);
        }
        return false;
      });
    },
    async removeEmailCoachingTemplate(key) {
      if (this.emailCoaching.default) {
        // As we get the default emailCoaching items, there are not items saved on the DB yet,
        // so we remove the item of the store and save it on the DB.
        this.$store.commit('removeEmailCoachingItem', key);
        this.save();
      } else {
        const updatedEmailCoaching = await emailCoachingService.removeEmailCoachingTemplate(key);
        this.$store.commit('updateEmailCoachingItems', updatedEmailCoaching.get('items'));
      }
    },
    addEmailCoaching() {
      this.showAddEmailCoaching = true;
    },
  }
};
</script>

<style scoped>
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }

  label.title {
    font-size: 17px !important;
  }
  .email-coaching-container div:first-of-type {
    margin-top: 0 !important;
  }

  .error-label {
    color: red;
  }

  .add-link {
    font-size: 13px;
    cursor: pointer;
    margin: 1rem 0 0 1rem;
  }
</style>
